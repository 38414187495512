import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Navbar, Nav } from "react-bootstrap"
import { Typeahead } from "react-bootstrap-typeahead"

import { FetchRecipes, IRecipeDescriptor } from "../apis/RecipesApi"

interface IData {
  id: string,
  label: string
}

export const Header: React.FC = () => {

  // Setting up the State
  const [data, setData] = useState([] as IData[])
  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()

  // Load data
  const onLoad = async () => {
    if (data.length!==0) return

    if (!isLoading) {
      setIsLoading(true)

      const lData: IData[] = []
      try {
        const recipes: IRecipeDescriptor[] = await FetchRecipes()
        await recipes.forEach((entry) => {
          lData.push({ id: entry.id, label: entry.name })
        })
        setData(lData)
      } catch (err) {
        console.error(err)
      }

      setIsLoading(false)
    }
  }

  // handle navigate to recipe page
  const onChange = (selected: IData[]) => {
    if (typeof (selected[0]) != "undefined") {
      const id = selected[0].id
      history.push("/recipe/" + id )
    }
  }

  const searchbox = (isLoading)
    ?
    <Typeahead
      id="search-box"
      options={[]}
      placeholder="טוען..."
      emptyLabel="טוען..."
    />
    :
    <Typeahead
      id="search-box"
      options={data}
      minLength={1}
      align="right"
      emptyLabel="לא נמצאו מתכונים"
      placeholder="חיפוש..."
      onChange={onChange}
      onFocus={onLoad}
    />

  return (
    <div className="header">
      <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
        <Navbar.Brand id="brand" href="/"><img src="/logo192.png" alt="chefef.com" height="48px" width="48px" />המחברת של אפרת</Navbar.Brand>
        <Nav className="justify-content-between searchbox">
          {searchbox}
        </Nav>
      </Navbar>
    </div>
  )
}
