import React, { useEffect } from "react"
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import "./App.css"
import { Header } from "./components/Header"
import { Footer } from "./components/Footer"
import { HomePage } from "./pages/HomePage"
import { RecipesByTagPage } from "./pages/RecipesByTagPage"
import { RecipePage } from "./pages/RecipePage"

const App: React.FC = () => {

  // ////////////////////////////////
  // When component is first loaded
  // ////////////////////////////////

  useEffect(() => {
    console.log("web environment:", process.env.NODE_ENV)
  },
    [] // this is important to make sure the useEffect is called only once for the first render
  )

  // ////////////////////////////////
  // Do the routing
  // ////////////////////////////////

  return (
    <Router >
      <Header />
      <div className="content">
        <Switch>
          <Route path="/" component={HomePage} exact />
          <Route path="/recipes/tag/:tag" component={RecipesByTagPage} exact />
          <Route path="/recipe/:id" component={RecipePage} exact />
          <Route component={HomePage} />
        </Switch>
      </div>
      <Footer />
    </Router>
  )
}

export default App
