import * as React from "react"
import { Navbar, Nav } from "react-bootstrap"


export const Footer: React.FC = () => {
    return (
        <div>
            <Navbar bg="dark" variant="dark" expand="lg" fixed="bottom" className="justify-content-center" >
                <Nav>
                    <Nav.Link href="/" >{new Date().getFullYear()} chefef.com</Nav.Link>
                </Nav>
            </Navbar>
        </div >
    )
}
