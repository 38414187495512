import axios from "axios"

import * as config from "../config"

// interface definition
export interface IRecipe {
    id: string,
    name: string,
    imageUrl: string,
    ingredients1: string,
    ingredients2: string,
    making: string,
    comments: string,
    tag_favorite: boolean,
    tags: string[]
}

export const FetchRecipe = async (id: string): Promise<IRecipe> => {
    const url = config.CBaseUrl + "/v1/recipe/"+id
    return new Promise(function (resolve, reject) {
        axios
            .get(url)
            .then(result => {
                resolve(result.data)
            })
            .catch(error => {
                console.error(error)
                reject(error)
            })
    })
}
