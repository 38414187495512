
// External imports
import React, { useEffect, useState } from "react"
import { Container, Row, Card } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-regular-svg-icons'


// Internal imports
import { FetchRecipe, IRecipe } from "../apis/RecipeApi"
import { useParams } from "react-router-dom"

// The render
export const RecipePage: React.FC = () => {

    const { id } = useParams();

    // Setting up the State
    const [recipe, setRecipe] = useState({} as IRecipe)

    // ////////////////////////////////
    // When component is first loaded
    // ////////////////////////////////
    useEffect(() => {
        const refreshData = async () => {
            setRecipe({} as IRecipe)
            try {
                const recipe: IRecipe = await FetchRecipe(id)
                setRecipe(recipe)
            } catch (err) {
                console.error(err)
            }
        }

        refreshData()
    },
        [id] // this is important to make sure the useEffect is called only once for the first render
    )

    const renderedFavorite = (!recipe.tag_favorite) ? <span></span> : <FontAwesomeIcon icon={faHeart} className="star" />

    // ////////////////////////////////
    // Render the data
    // ////////////////////////////////
    return (
        <Container>
            <Helmet>
                <title>ChefEf</title>
                <meta name="description" content={"Chefef - " + recipe.name} />
            </Helmet>

            <Row>
                <Card className="big-card">
                    <Card.Header><h2>{renderedFavorite} {recipe.name}</h2></Card.Header>
                    <Card.Img variant="top" src={recipe.imageUrl} />
                    <Card.Body>
                        <Card.Text>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Row>
            <Row>
                <span className="display-linebreak">{recipe.comments}</span>
            </Row>
            <Row>
                <h2>החומרים</h2>
            </Row>
            <Row >
                <span className="display-linebreak">{recipe.ingredients1}</span>
                <span className="display-linebreak">{recipe.ingredients2}</span>
            </Row>
            <Row>
                <h2>אופן ההכנה</h2>
            </Row>
            <Row>
                <span className="display-linebreak">{recipe.making}</span>
            </Row>
        </Container>
    )
}
