import axios from "axios"

import * as config from "../config"

// interface definition
export interface ITag {
    id: string,
    tag: string,
    name: string,
    image: string
}

export const FetchTags = async (): Promise<ITag[]> => {
    const url = config.CBaseUrl + "/v1/tags"
    return new Promise(function (resolve, reject) {
        axios
            .get(url)
            .then(result => {
                resolve(result.data)
            })
            .catch(error => {
                console.error(error)
                reject(error)
            })
    })
}
