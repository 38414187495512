
// External imports
import React, { useEffect, useState } from "react"
import { Container, Row, Card, NavLink } from "react-bootstrap"
import { Helmet } from "react-helmet"

// Internal imports
import { FetchTags, ITag } from "../apis/TagsApi"

// The render
export const HomePage: React.FC = () => {

    // Setting up the State
    const [tags, setTags] = useState([] as ITag[])

    // ////////////////////////////////
    // When component is first loaded: Load the recaptcha javascript
    // ////////////////////////////////
    useEffect(() => {

        refreshData()
    },
        [] // this is important to make sure the useEffect is called only once for the first render
    )

    const refreshData = async () => {
        setTags([] as ITag[])
        try {
            const tags: ITag[] = await FetchTags()
            setTags(tags)
        } catch (err) {
            console.error(err)
        }
    }

    const renderedTags = (typeof (tags) === "undefined") ? <div></div>
        : tags.map((entry, index) =>
            <NavLink key={entry.tag} href={"/recipes/tag/" + entry.tag}>
                <Card bg="light" className="card" border="primary">
                    <Card.Img variant="top" src={entry.image} />
                    <Card.Body>
                        <Card.Title>{entry.name}</Card.Title>
                    </Card.Body>
                </Card>
            </NavLink>
        )

    // ////////////////////////////////
    // Render the data
    // ////////////////////////////////
    return (
        <Container>
            <Helmet>
                <title>ChefEf</title>
                <meta name="description" content="Chefef - Hebrew recipes for homemade cooking" />
            </Helmet>

            <Row>
                {renderedTags}
            </Row>
        </Container>
    )
}
