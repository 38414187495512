
// External imports
import React, { useEffect, useState } from "react"
import { Container, Row, Card, NavLink } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-regular-svg-icons'
import { useParams } from "react-router-dom"

// Internal imports
import { FetchRecipesByTag, IRecipeDescriptor } from "../apis/RecipesApi"

// The render
export const RecipesByTagPage: React.FC = () => {

    const { tag } = useParams();

    // Setting up the State
    const [recipes, setRecipes] = useState([] as IRecipeDescriptor[])

    // ////////////////////////////////
    // When component is first loaded
    // ////////////////////////////////
    useEffect(() => {
        const refreshData = async () => {
            setRecipes([] as IRecipeDescriptor[])
            try {
                const recipes: IRecipeDescriptor[] = await FetchRecipesByTag(tag)
                setRecipes(recipes)
            } catch (err) {
                console.error(err)
            }
        }

        refreshData()
    },
        [tag] // this is important to make sure the useEffect is called only once for the first render
    )

    const renderedRecipes = (typeof (recipes) === "undefined") ? <div></div>
        : recipes.map((entry, index) =>
            <NavLink key={entry.id} href={"/recipe/" + entry.id}>
                <Card bg="light" className="card" border="primary">
                    <Card.Img variant="top" src={entry.imageUrl} />
                    <Card.Body>
                        <Card.Title>
                            {(!entry.tag_favorite) ? <span></span> : <FontAwesomeIcon icon={faHeart} className="star" />}&nbsp;
                            {entry.name}
                        </Card.Title>
                    </Card.Body>
                </Card>
            </NavLink>
        )

    // ////////////////////////////////
    // Render the data
    // ////////////////////////////////
    return (
        <Container>
            <Helmet>
                <title>ChefEf</title>
                <meta name="description" content={"Chefef - " + tag} />
            </Helmet>

            <Row>
                {renderedRecipes}
            </Row>
        </Container>
    )
}
