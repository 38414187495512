import axios from "axios"

import * as config from "../config"

// interface definition
export interface IRecipeDescriptor {
    id: string,
    name: string,
    tag_favorite: boolean,
    imageUrl: string
}

export const FetchRecipesByTag = async (tag: string): Promise<IRecipeDescriptor[]> => {
    const url = config.CBaseUrl + "/v1/recipes/tag/"+tag
    return new Promise(function (resolve, reject) {
        axios
            .get(url)
            .then(result => {
                resolve(result.data)
            })
            .catch(error => {
                console.error(error)
                reject(error)
            })
    })
}

export const FetchRecipes = async (): Promise<IRecipeDescriptor[]> => {
    const url = config.CBaseUrl + "/v1/recipes/"
    return new Promise(function (resolve, reject) {
        axios
            .get(url)
            .then(result => {
                resolve(result.data)
            })
            .catch(error => {
                console.error(error)
                reject(error)
            })
    })
}
